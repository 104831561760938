







































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapState, mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import BigNumber from "bignumber.js";

import filtersMixin from "@/mixins/filters";
import { CONTRACT, selectedNetwork } from "@/network";
import { Currency, GasPrice, GasPriceInfo } from "@/types";
import state from "@/contract";
import { getEthPrice, getImgUrl } from "@/helpers";
import { initToken } from "@/init";

import Popup, { TRANSACTION_STATUS } from "@/components/UI/Popup.vue";
import Alert from "@/components/UI/Alert.vue";

@Component({
    name: "Withdraw",
    mixins: [filtersMixin],
    components: {
        vSelect,
        Alert,
    },
    data: () => ({
        coins: state.coins
    }),
    computed: {
        ...mapState([
            'client',
            'setLiquidity3PoolToken'
        ]),
        ...mapGetters([
            'getGasPriceOptions'
        ])
    },
    methods: {
        getImgUrl,
        ...mapActions([
          'UPDATE_BALANCES'
        ]),
    },
})
export default class AddLiquidity extends Vue {
    // TODO: add type to client
    public client!: any;
    public setLiquidity3PoolToken!: BigNumber;
    private isAdvancedOptionsOpened = false;
    private isWithdrawActive = false;

    private showAlert = false;
    private isExchangePending = false;
    private transactionError = false;
    private alertText = '';
    private alertTitle = '';
    private maxSlippage = '1.5';
    private isAmountErrors = false;

    private minLPTokensAmount = new BigNumber(0);
    private removeLiquidityAmount = '';

    private coins: Currency[] = state.coins;

    // loading token list from state
    private amounts: { [key: string]: string } = state.coins.reduce((obj, c) => ({
        ...obj,
        [c.address]: '',
    }), {})

    getGasPriceOptions!: GasPriceInfo[];
    private gasOptionsSelect = { label: '', value: new BigNumber(1e9) };
    private selectedWithdrawCoin: Currency|null = null; // undefined means withdraw balanced

    // TODO: when this is ON, calculate optimal proportions as balanced pool values
    private isWithdrawBalancedActive = true;

    private ethPrice = 0; // USD
    private gasLimit = 300_000;
    private USE_GAS_LIMIT = 300_000;
    private delayTimer: NodeJS.Immediate | null = null;


    mounted() {
        this.init()
        // this.removeLiquidityAmount = '100'
        // this.updateSetWithdrawMaxLP()
    }

    async init () {
        console.log('init')
        await getEthPrice(selectedNetwork.chain)
        this.updateResetAmounts()

        this.ethPrice = state.ethPrice
        this.gasOptionsSelect = this.getGasPriceOptions.slice(-1)[0];
    }

    @Watch('getGasPriceOptions')
    onPropertyChanged(value: GasPriceInfo[]) {
        this.gasOptionsSelect = value.slice(-1)[0];
    }

    get gasPrice (): GasPrice {
        return new BigNumber(this.gasOptionsSelect.value)
    }

    shouldShowError(balance: BigNumber, amount: any) {
        this.isAmountErrors = new BigNumber(amount).gt(balance) || new BigNumber(amount).lte(0) || !amount

        return this.isAmountErrors
    }

    shouldShowMaxButton(balance: any, amount: any) {
        const isAmountMax = new BigNumber(amount).isEqualTo(balance)

        return !!balance && !isAmountMax
    }

    isSelected(coin: Currency) {
        return this.selectedWithdrawCoin && this.selectedWithdrawCoin.index == coin.index
    }

    openAdvOptions() {
      this.isAdvancedOptionsOpened = !this.isAdvancedOptionsOpened;
    }

    changeWithdraw() {
        this.isWithdrawActive = !this.isWithdrawActive
    }

    updateResetAmounts() {
        Object.keys(this.amounts).forEach(key => {
            this.amounts[key] = ''
        })
    }

    get txCostUSD () {
        return (this.gasPrice.toNumber() * this.gasLimit * this.ethPrice) / 1e9;
    }

    setWithdrawCoin(coin: Currency) {
        this.selectedWithdrawCoin = coin
        this.isWithdrawBalancedActive = false

        this.updateAmountsOneCoin()
    }

    setWithdrawBalanced () {
        if (this.isWithdrawBalancedActive) {
            this.updateSetWithdrawMax(this.coins[0])
        } else {
            this.selectedWithdrawCoin = null
    
            this.updateAmounts()
        }
    }
    updateSetWithdrawMaxLP () {
        this.selectedWithdrawCoin = null
        this.isWithdrawBalancedActive = true
        this.removeLiquidityAmount = this.setLiquidity3PoolToken.toFixed(18)

        this.updateAmounts()
    }

    updateSetWithdrawMax (coin: Currency) {
        if (!state.isReady) { throw new Error("State Not Loaded yet") }

        this.removeLiquidityAmount = this.setLiquidity3PoolToken.toFixed(18)

        this.setWithdrawCoin(coin)
    }


    async updateAmounts() {
        if (!state.isReady) { throw new Error("State Not Loaded yet") }

        if (this.selectedWithdrawCoin) {
            this.updateAmountsOneCoin()
            return
        }

        this.delayTimer && clearImmediate(this.delayTimer);
        
        this.delayTimer = setImmediate(async () => {
            if (!state.isReady) { throw new Error("State Not Loaded yet") }

            this.selectedWithdrawCoin = null
            this.isWithdrawBalancedActive = true

            console.log('calc how much to receive manually')

            const lpToken = await initToken(state.web3, CONTRACT.xsigmaLpToken);

            const amount = new BigNumber(this.removeLiquidityAmount || 0).times(lpToken.precision)

            console.log('amount', amount.toFixed(0))

            const totalSupply = await lpToken.contract.methods.totalSupply().call()

            const amounts = await Promise.all(this.coins.map(async coin => {
                if (!state.isReady) { throw new Error("State Not Loaded yet") }

                const balance = await state.swapContract.methods.balances(coin.index).call()

                console.log('balance', balance)

                const _amount = amount.times(balance).div(totalSupply)

                this.$set(this.amounts, coin.address, _amount.div(coin.precision).toString());

                return _amount
            }))

            console.log('amounts', amounts)

            // const amounts = 

            // const amounts = this.coins.map(coin =>
            //   new BigNumber(this.amounts[coin.address] || 0).times(coin.precision),
            // );

            console.log('amounts', amounts.map(bn => bn.toFixed(0)));

            const isDeposit = false;

            const estimateLPTokensAmount = await state.swapContract.methods.calc_token_amount(
                amounts.map(bn => bn.toFixed(0)),
                isDeposit,
            ).call();

            console.log('estimate', estimateLPTokensAmount);

            console.log('amount', amount.toFixed(0))

            console.log('estimateLPTokensAmount', new BigNumber(estimateLPTokensAmount).toString())

            const gasLimit = await state.swapContract.methods
                .remove_liquidity(
                    amount.toFixed(0,1),
                    [0,0,0],
                )
                .estimateGas({
                    from: state.defaultAccount,
                })

            this.gasLimit = gasLimit

            console.log('estimate gasLimit', this.gasLimit)

            this.delayTimer && clearImmediate(this.delayTimer);

            console.log('delayTimer was', this.delayTimer)
            this.delayTimer = null;


        })
    }

    goToLiquidity() {
        this.$router.push('/deposit')
    }

    async updateAmountsOneCoin () {
        if (!state.isReady) { throw new Error("State Not Loaded yet") }

        this.delayTimer && clearImmediate(this.delayTimer);
        
        this.delayTimer = setImmediate(async () => {
            try {

                if (!state.isReady) { throw new Error("State Not Loaded yet") }
    
                const coin = this.selectedWithdrawCoin
    
                if (!coin) { throw new Error("Unknown coin") }
    
                const amount = this.removeLiquidityAmount || 0

                // if (!amount)
    
                const lpToken = await initToken(state.web3, CONTRACT.xsigmaLpToken);
    
                const withdrawAmount = new BigNumber(amount).times(lpToken.precision)
    
                console.log('calc_withdraw_one_coin', withdrawAmount, coin.index)
    
                const estimateReceiveAmount = await state.swapContract.methods.calc_withdraw_one_coin(
                    withdrawAmount.toFixed(0,1),
                    coin.index,
                ).call()
    
                console.log('estimate receive', coin.symbol, estimateReceiveAmount)
    
                const receiveTokens = new BigNumber(estimateReceiveAmount).div(coin.precision)
    
                this.updateResetAmounts()
    
                console.log('amounts', coin.address, '=>', receiveTokens.toString())
    
                this.$set(this.amounts, coin.address, receiveTokens.toString())
    
                const slippage = parseFloat(this.maxSlippage);
    
                const gasLimit = await state.swapContract.methods
                    .remove_liquidity_one_coin(
                        withdrawAmount.toFixed(0,1),
                        coin.index,
                        receiveTokens.times(coin.precision).times(100 - slippage).div(100).toFixed(0),
                    )
                    .estimateGas({
                        from: state.defaultAccount,
                    })
    
                this.gasLimit = gasLimit
    
                console.log('estimate gasLimit', this.gasLimit)
    
                // this.delayTimer && clearImmediate(this.delayTimer);
    
            } catch (err) {
                console.error('error updaing amounts', err)    
            } finally {
                    
                console.log('delayTimer was', this.delayTimer)
                this.delayTimer = null;

            }

        })
    }

    doWithdraw () {
        if (!state.isReady) { throw new Error("State Not Loaded yet") }
        const amount = new BigNumber(this.removeLiquidityAmount)

        if (!amount) { return console.error('Not loaded') }

        const slippage = parseFloat(this.maxSlippage);

        // console.log('estimateLPTokensAmount', new BigNumber(estimateLPTokensAmount).toString())

        // const amounts = await Promise.all(this.coins.map(async coin => {
        //     if (!state.isReady) { throw new Error("State Not Loaded yet") }

        //     const balance = await state.swapContract.methods.balances(coin.index).call()

        //     console.log('balance', balance)

        //     const _amount = amount.times(balance).div(totalSupply)

        //     this.amounts[coin.address] = _amount.div(coin.precision).toString();

        //     return _amount
        // }))

        const minAmounts = this.coins.map(coin =>
            new BigNumber(this.amounts[coin.address] || 0)
                .times(100 - slippage).div(100)
                .times(coin.precision)
        );

        this.withdraw(amount.times(1e18), this.selectedWithdrawCoin, minAmounts);
    }

    async withdraw(amount: BigNumber, selectedWithdrawCoin: Currency|null, minAmounts?: BigNumber[]) {
        if (!state.isReady) { throw new Error("State Not Loaded yet") }

        const minAmountsStr = minAmounts?.map(bn => bn.toFixed(0)) || [0,0,0]

        console.log('withdraw', 
            amount.toFixed(0),
            minAmounts,
            minAmountsStr,
        );

        // TODO: fix estimateGas error
        // const gasLimit = await state.swapContract.methods
        //     .remove_liquidity(
        //         amount.toFixed(0),
        //         minAmounts?.map(bn => bn.toFixed(0)) || [0,0,0],
        //     ).estimateGas();

        // console.log('gasLimit', gasLimit);

        const gasPrice = this.gasPrice.times(1e9)
        let result;
        try {
            this.alertTitle = TRANSACTION_STATUS.waitingForConfirmationTitle;
            this.alertText = TRANSACTION_STATUS.waitingForConfirmationText;
            this.showAlert = true;

            console.log('selectedWithdrawCoin && selectedWithdrawCoin.index', selectedWithdrawCoin && selectedWithdrawCoin.index)

            const transaction = selectedWithdrawCoin && selectedWithdrawCoin.index !== undefined
                ? state.swapContract.methods
                    .remove_liquidity_one_coin(
                        amount.toFixed(0),
                        selectedWithdrawCoin.index,
                        minAmountsStr[selectedWithdrawCoin.index],
                    )
                : state.swapContract.methods
                    .remove_liquidity(
                        amount.toFixed(0),
                        minAmountsStr,
                    )
            
            console.log('transaction', transaction)

            result = transaction
              .send({
                  from: state.defaultAccount,
                  gasPrice,
                  gasLimit: this.USE_GAS_LIMIT,
                  // gasLimit,
              });
            result.on('transactionHash', (hash: string) => {
                console.log('tx hash', hash)
                this.alertTitle = '';
                this.alertText = TRANSACTION_STATUS.created;
                this.showAlert = true;
                this.isExchangePending = true;
                state.transactionPending = true;
            })
            result.on('receipt', (receipt: any) => {
                console.log('tx hash', receipt.transactionHash)

                this.$notify(Popup.tx.success(TRANSACTION_STATUS.withdrawCompleted, receipt.transactionHash))

                this.isExchangePending = false;
                state.transactionPending = false;
                this.$store.dispatch('UPDATE_BALANCES')
                this.$store.dispatch('GET_3POOL_LIQUIDITY_TOKEN')
                this.removeLiquidityAmount = ''
                this.init()
            })
            result.on('error', (error: any) => {
                console.error('error', error)
                this.alertText = TRANSACTION_STATUS.errorMessage + error?.message;
                this.transactionError = true
                this.showAlert = true;
                this.isExchangePending = false;
                state.transactionPending = false
                setTimeout(() => {
                    this.showAlert = false
                    this.transactionError = false
                }, 2000)
            })
        } catch (e) {
            this.alertText = TRANSACTION_STATUS.stakeReverted;
            this.showAlert = true;
            this.isExchangePending = false;
            state.transactionPending = false
        }
    }
}
