











import Withdraw from "@/components/Withdraw.vue";

export default {
    name: "WithdrawView",
    components: {
        Withdraw,
    },
}
